import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import { i18n, availableLocales } from "../i18n";
import Root from "../Root.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: `/${i18n.global.locale.value}`
  },
  {
    path: "/:lang",
    component: Root,
    children: [
      {
        path: "",
        alias: "home",
        name: "Home",
        meta: { requiresAuth: true },
        component: () => import("../views/Home.vue")
      },
      {
        path: "login",
        name: "Login",
        meta: { layout: "blank" },
        component: () => import("../views/Login.vue")
      },
      {
        path: "about",
        name: "About",
        meta: { requiresAuth: true },
        component: () => import("../views/About.vue")
      },
      {
        path: "library/publications",
        name: "Publications",
        meta: { requiresAuth: true },
        component: () => import("../views/Publications.vue")
      },
      {
        path: "library/links",
        name: "Links",
        meta: { requiresAuth: true },
        component: () => import("../views/Links.vue")
      },
      {
        path: "library/scientific-brochures",
        name: "ScientificBrochures",
        meta: { requiresAuth: true },
        component: () => import("../views/ScientificBrochures.vue")
      },
      {
        path: "library/videos",
        name: "VideosLibrary",
        meta: { requiresAuth: true },
        component: () => import("../views/VideosLibrary.vue")
      },
      {
        path: "materials/poop",
        name: "Poop",
        meta: { requiresAuth: true },
        component: () => import("../views/Poop.vue")
      },
      // LACTATION DOES NOT EXIST IN AZERBAINJAN
      // {
      //   path: "materials/complementary-feeding",
      //   name: "ComplementaryFeeding",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/ComplementaryFeeding.vue")
      // },
      {
        path: "materials/brochures",
        name: "Brochures",
        meta: { requiresAuth: true },
        component: () => import("../views/Brochures.vue")
      },
      // LACTATION DOES NOT EXIST IN GEORGIA OR AZERBAINJAN
      // {
      //   path: "materials/lactation",
      //   name: "Lactation",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/Lactation.vue")
      // },
      {
        path: "materials/videos",
        name: "VideosMaterials",
        meta: { requiresAuth: true },
        component: () => import("../views/VideosMaterials.vue")
      },
      // EASY TRACKING DOES NOT EXIST IN GEORGIA OR AZERBAINJAN
      // {
      //   path: "easy-tracking",
      //   name: "EasyTracking",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/EasyTracking.vue")
      // },
      {
        path: "portfolio",
        name: "Portfolio",
        meta: { requiresAuth: true },
        component: () => import("../views/Portfolio.vue")
      },
      {
        path: "product/:slug",
        name: "Product",
        meta: { requiresAuth: true },
        component: () => import("../views/Product.vue")
      },
      {
        path: "calculator/:slug",
        name: "Calculator",
        meta: { requiresAuth: true },
        component: () => import("../views/Calculator.vue")
      }
    ]
  },
  // {
  //   path: "/:catchAll(.*)",
  //   redirect: { name: "Home", params: { lang: fallbackLocale } }
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // if (to.params.lang === from.params.lang) {
  //   next()
  //   return
  // }
  const langParam = String(to.params.lang);
  if (availableLocales.includes(langParam)) {
    i18n.global.locale.value = langParam;
  }

  const isAuthenticated = store.getters.isUserAuthenticated;

  if (to.meta.layout === "blank") store.commit("setBlankLayout");
  else store.commit("setDefaultLayout");

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "Login", params: { lang: i18n.global.locale.value } });
  } else if (to.name == "Login" && isAuthenticated) {
    next({ name: "Home", params: { lang: i18n.global.locale.value } });
  } else {
    next();
  }
});

export default router;
