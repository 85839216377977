export default {
  "common": {
    "completeGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam bələdçi"])},
    "selectBottomButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məzmununu oxumaq və ya paylaşmaq üçün aşağıdakı düyməyə basın."])},
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAHA&nbsp;ÇOX&nbsp;ÖYRƏNİN!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["çıxış"])}
  },
  "header": {
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "growthCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesablamalar"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitabxana"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəşrlər"])},
      "scientificBrochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmi broşurla"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkləri"])},
      "materialsForParents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideynlər üçün maarifləndirici materiallar"])},
      "healthyStools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlam nəcis"])},
      "complementaryFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə qidalanma"])},
      "brochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broşurlar"])},
      "breastfeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
      "easyTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə"])},
      "aboutFriso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso haqqında"])}
    }
  },
  "footer": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibb mütəxəssisləri üçün xüsusi material. Ana südü körpə üçün ən yaxşı qidadır."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxilən daha güclü olsaq birlikdə daha çox təcrübə əldə edə bilərik!"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi burada tapa bilərsiniz:"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bütün hüquqlar qorunur"])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əsas brendlərimiz olan FRISO<sup>&reg;</sup> və FRISOLAC<sup>&reg;</sup> daxil olmaqla, lakin bununla məhdudlaşmayan bu veb-saytın məzmunu FrieslandCampina-nın qorunan əqli mülkiyyətidir."])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso haqqında"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>&reg;</sup>, dünyanın aparıcı süd şirkətlərindən biri olan FrieslandCampina tərəfindən Hollandiyada istehsal olunan və qablaşdırılan qlobal brenddir. Friso<sup>&reg;</sup> məhsullarımızdakı süd öz fermalarımızdan gəlir. Torpağın keyfiyyətindən tutmuş inəklərin bəslənməsinə qədər təsərrüfatlarımıza xüsusi diqqət yetiririk. Fermerlərimiz 150 ildən çox südçülük təcrübəsi olan fəxri fermer ailələrindəndir."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360° videolarda siz 23000-dən çox həmkarın hər gün tətbiq etdikləri bilik, peşəkarlıq və həvəsləri ilə tanış olacaqsınız."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu tur sizi sübh tezdən  Hollandiyanın gözəl çəmənliklərinə aparacaq. Ferma ilə tanış olduqdan sonra siz ən müasir İnnovasiya Mərkəzinə baş çəkəcək və ən mühüm körpə  süd qatışıqları istehsal edən zavodlardan birinə ekskursiya edəcəksiniz. Nəhayət, siz Friso® istifadə edən ana və qızının gündəlik həyatından adi bir gün yaşayacaqsınız. Qısası, bütün Friso<sup>&reg;</sup> səyahəti boyunca bir tur: Otdan Şüşəyədək."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksək keyfiyyətli körpə süd qatışığının hazırlanması üçün sağlam inəklər, həmçinin ən məhsuldar torpaqda yetişən ən yaxşı ot lazımdır. Vollering ailəsinin öz fermalarını nə qədər həvəslə idarə etdiklərini və inəklərini necə yaxşı bəslədiklərini görmək üçün onların fermasına baş çəkib ətraf mühiti özünüz müşahidə edə bilərsiniz."])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>&reg;</sup> süd qatışığı öz fermalarımızdan əldə etdiyimiz yüksək keyfiyyətli südə əsaslanır. Körpələrin qida ehtiyaclarını ödəmək üçün uyğunlaşdırılmalıdır. Burada müxtəlif təcrübə sahələrindən olan mütəxəssislər məhsullarımızı daim təkmilləşdirmək üçün birlikdə çalışır."])},
    "textF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FrieslandCampina bütün istehsal prosesinə tam nəzarət edir. Biz təzə südü birbaşa öz fermalarımızdan alırıq və digər istehsalçılardan fərqli olaraq südü yalnız bir dəfə çox zəif temperaturda qızdırıraraq toz halına çeviririk. Bu, körpə süd qatışığı sənayesində unikal proses olaraq böyük üstünlük yaradır: tərkibində olan bütün əsas qida maddələri zədələnməmiş qalır və son məhsul asan həzm olunur."])},
    "textG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandiyaya getdiyiniz zaman velosipediniz olmalıdır! Sietske və onun qızı Zara ilə birlikdə velosiped sürərək tipik Hollandiya mənzərəsini gəzə və yol boyu bəzi görməli yerləri görə bilərsiniz."])},
    "textH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu turda bizə qoşulduğunuz üçün təşəkkür edirik: Təbiətlə hazırlanıb, Elmlə daha da təkmilləşdirilib, Friso®-nun Otdan Şüşəyədək bütün istehsal prosesini tam nəzarətdə saxlayan yeganə brend olduğunun şahidi oldunuz."])},
    "textI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandiyaya səfər etsəniz fermalarımızdan birinə baş çəkə bilərsiniz."])},
    "textJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ziens! (Əlvida!)"])},
    "subtitleA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təbiətlə hazırlanmışdır"])},
    "subtitleB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmlə daha da təkmilləşdirlilib"])},
    "subtitleC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikal emal"])},
    "subtitleD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandiyaya xoş gəlmisiniz!"])},
    "popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videonun 360 effekti yalnız YouTube tətbiqindən istifadə edərkən işləyir. Baxmağa başlamazdan əvvəl bunun telefonunuzda işlədiyinə əmin olun."])},
    "popupAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davam edin"])}
  },
  "easyTracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə Sistemi nədir?"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandiyadakı öz fermalarımızdan başlayaraq, Friso qatışığının istehsalının hər addımına ciddi nəzarət edə bilərsiniz."])},
    "cardA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermalarımız"])},
    "cardB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süd<br/>növləri"])},
    "cardC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qablaşdırma"])},
    "cardD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyfiyyətə son<br/>nəzarət"])},
    "cardE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İxracın<br/>təsdiqi"])}
  },
  "poop": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı həzm körpənizin böyüməsi və inkişafı üçün vacibdir. Uşağınızın həzm sisteminin sağlamlığının ən gözə çarpan göstəricisi onun nəcisidir! Uşağınıza yaxşı defekasiyaya nail olmaq üçün necə kömək edə bilərsiniz."])},
    "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rənglər"])},
    "shapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formalar"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pis"])},
    "goodColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı Rənglər"])},
    "goodColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>rənglər</b> normal dəyişikliklərdir."])},
    "greenishCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarı qəhvəyi"])},
    "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qəhvəyi"])},
    "coffeeYellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaşımtıl qəhvəyi"])},
    "darkGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tünd yaşıl"])},
    "badColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pis Rənglər"])},
    "badColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>rənglər</b> körpələrdə qanaxma və ya həzm problemlərinin əlamətlərini göstərə bilər."])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qırmızı"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qara"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ"])},
    "withRedBars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qırmızı zolaqlarla"])},
    "goodShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı formalar"])},
    "goodShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunlar asanlıqla xaric oluna  bilən ideal <b>formalardır</b>."])},
    "spongy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süngərli"])},
    "sausage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yumşaq kolbasa"])},
    "badShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyğun olmayan formalar"])},
    "badShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>formalar</b> susuzlaşdırma, lif çatışmazlığı, pis qidalanma və / və ya stress əlamətlərini göstərir."])},
    "parsley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cəfəri"])},
    "parsleyImageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parsley_en"])},
    "oats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yulaf"])},
    "grape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzüm"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toyuq Nagetsləri"])},
    "rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovşan nəcisi"])}
  },
  "complementaryFeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə qidalanma"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinir sisteminin inkişafı və qidalanma arasında olan əlaqə"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop_en.png"])},
    "imageA2xUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop2x_en.png"])}
  },
  "breatsfeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktasiya"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Həkimlər həyatın ən azı ilk altı ayında yalnız ana südü ilə qidalandırmağı məsləhət görürlər"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ümumdünya Səhiyyə Təşkilatı (ÜST) ilk altı ay ərzində yalnız ana südü ilə qidalanmağı daha sonra yaşa uyğun və təhlükəsiz qidaların tətbiqini, 2 yaş və ya ondan yuxarı olana qədər ana südü ilə qidalandırmanın davam etdirilməsini tövsiyə edir"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, anaların müxtəlif vəziyyətlərindən asılı olaraq dəyişə bilər: bir çoxları işə daha erkən qayıtmalı olur, digərləri uşaqlarını erkən yaşlarından daha müstəqil etmək istəyə bilər və ya ata ilə qidalanma məsuliyyətini bölüşdürə bilər ki, onlar da bağlılıq hiss etsinlər."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cəmiyyət yeni doğulmuş körpəni necə və nə vaxt qidalandırmaq üçün təzyiq göstərir. Bu mövzuda, heç kim sizə uşağınız üçün nəyin ən yaxşı olduğunu deyə bilməz, çünki onun həyat tərzini və vərdişlərini hamıdan daha yaxşı bilən yalnız siz olacaqsınız. Beləliklə, körpəniz üçün ən yaxşı qidanın ana südü olduğu bir faktdır, ona görə də sizə ən azı həyatının ilk altı ayında körpənizi əmizdirməyə cəhd etməyi məsləhət görürük."])}
  },
  "materialsVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasiyentlərin valideynlərini maraqlandıran mövzuları göstərmək üçün video seçin."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozanın Faydaları"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficios-de-la-lactosa-consumidores.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-b.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpəmin laktozaya qarşı dözümsüz olduğunu necə bilə bilərəm?"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video-lactosa-para-consumidores-pisa.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-c.png"])}
      }
    }
  },
  "publications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəşrlər"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso-nun elmə əsaslanaraq hazırlanan məhsulları haqqında ətraflı öyrənmək üçün məqalələri seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal termo emalla kommersial olaraq əlçatan qatışıqla qidalanan çin körpələrində mədə-bağırsaq simptomlarının tezliyinin azalması:çarpaz müşahidə tədqiqatı"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout and Panam Parikh"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan Qidalanmasında Laktozanın Əhəmiyyəti: Meksika Konsensus Yığıncağının nəticələri"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero and Jan Geurts"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consenso-de-lactosa-traduccion-documento.pdf"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə Qatışıqlarının model Sistemlərində Miselyar Kalsium Fosfatın laboratoriya şəraitində Mədə Koaqulyasiyasına və Süd Zülalının Həzminə Təsiri"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorn Huppertz and Tim T. Lambers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emal süd zülalının həzminə və ümumi fizioloji nəticələrə necə təsir edə bilər: sistematik  baxış"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt and Kasper A. Hettinga"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə süd qatışıqları allergen aktivliyi və T-hüceyrə və sitokin reaksiyalarının induksiyası ilə fərqlənir"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol and S. Spitzauer & R. Valenta"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaxtından əvvəl doğulmuş körpələrin gec və az miqdarda qidalanması: Avropa Pediatrik Qastroenterologiya, Hepatologiya və Qidalanma Cəmiyyətinin Qidalanma Komitəsinin mövqe sənədi"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandre Lapillonne, Jiri Bronsky, Cristina Campoy, Nicholas Embleton, Mary Fewtrell, Nataša Fidler Mis, Konstantinos Gerasimidis, Iva Hojsak, Jessie Hulst, Flavia Indrio, Christian Molgaard, Sissel Jennifer Moltu, Elvira Verduci and Magnus Domellöf"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feeding-the-late-and-moderately-preterm-infant-lapillone-2019_en.pdf"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü allergiyasının qarşısının alınması, diaqnostikası və müalicəsi üçün strategiyalar və gələcək imkanlar"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Zepeda-Ortega, Anne Goh, Paraskevi Xepapadaki, Aline Sprikkelman, Nicolaos Nicolaou, Rosa Elena Huerta Hernandez, Amir Hamzah Abdul Latiff, Miu Ting Yat, Mohamed Diab, Bakr Al Hussaini, Budi Setiabudiawan, Urszula Kudla, R. J. Joost van Neerven, Leilani Muhardi and John O. Warner"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.pdf"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşaqlarda körpə sancıları, qusma və qəbizliyin yayılmasının yenilənməsinə dair hekayə icmalı: ROMA IV əlamətlərin nəticələri"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leilani Muhardi<sup>1</sup>, Marion M. Aw<sup>2,3</sup>, Mohammed Hasosah<sup>4,5</sup>, Ruey Terng Ng<sup>6</sup>, Sze Yee Chong<sup>7</sup>, Badriul Hegar<sup>8</sup>, Erick Toro-Monjaraz<sup>9</sup>, Andy Darma<sup>10</sup>, Merih Cetinkaya<sup>11</sup>, Chung Mo Chow<sup>12</sup>, Urszula Kudla<sup>13</sup> and Yvan Vandenplas<sup>14*</sup>"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022-muhardi-et-al-a-narrative-review-on-colic-reflux-and-constipation.pdf"])}
      },
      "i": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mədədə süd zülalının laxtalanması"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thom Huppertz<sup>a,b,*</sup>, Loo Wee Chiaa<sup>a</sup><br/><sup>a</sup> FrieslandCampina, Amersfoort, the Netherlands<br/><sup>b</sup> Wageningen University & Research, Wageningen, the Netherlands"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-huppertz-et-al-milk-protein-coagulation-under-gastric-conditions.pdf"])}
      }
    }
  },
  "links": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkləri"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, adipiscing quis non in mollit (AZ)"])},
    "items": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.friso.az"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Beynəlxalq"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.frieslandcampinainstitute.com"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Webshop"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#friso-webshop"])}
      }
    }
  },
  "scientificBrochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmi broşurla"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə və uşaqların qidalanması haqqında ətraflı öyrənmək üçün maraqlı olan mövzuları seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk formula with different glycation profile influences gastrointestinal outcomes in Chinese infants"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["milk-formula-with-different-glycation-profile-influences-gi-outcomes-in-chinese-infants_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Brochure 2020"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product-brochure-2020-azerbaijan_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-b.png"])}
      }
    }
  },
  "brochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broşurlar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maraq doğuran mövzuları seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Cows' Milk Protein Allergy (CMPA)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-cows-milk-allergy_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Functional Gastrointestinal Disorders (FGIDs)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-functional-gastrointestinal-disorders_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-b.png"])}
      }
    }
  },
  "libraryVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə və uşaqların qidalanması haqqında ətraflı öyrənmək üçün maraqlı olan mövzuları seçin."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süd yağının həzm edilməsi"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digestion-de-la-grasa-lactea.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozanı azaltmaq? İki dəfə düşünün!"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reducir-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozaya qarşı dözümsüzlük"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Campina - Fermer Hekayəsi"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-farmer-story_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-d.png"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südünə qarşı allergiyanın qarşısının alınması, diaqnostikası və müalicəsi üçün strategiyalar və gələcək imkanlar"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-publication-series-zepeda-et-al-strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-e.png"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tək və ikiqat emal"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glycation-single-versus-double-processing_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-f.png"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza - I hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-i_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-g.png"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza - II hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-ii_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-h.png"])}
      },
      "i": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergiya İdarəetmə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allergy-management_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-i.png"])}
      },
      "j": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut sağlamlığı - 1-ci hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-1_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-j.png"])}
      },
      "k": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut sağlamlığı - 2-ci hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-2_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-k.png"])}
      },
      "l": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut sağlamlığı - 3-cü hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-3_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-l.png"])}
      }
    }
  },
  "calculators": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlam körpənin ölçülərinin hesablanması"])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oğlan"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qız"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məlumatları düzgün doldurun"])},
    "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesablamaq"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəticələr"])},
    "headCircumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başın diametri"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boy"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzunluq"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki"])},
    "weightHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki ─ Boy"])},
    "weightLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki ─ Uzunluq"])},
    "dateBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum günü"])},
    "dateMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçmə tarixi"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaş"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylar"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İllər"])},
    "ageRangeYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " yașdan ", _interpolate(_list(1)), " yașa qǝdǝr"])},
    "ageRangeYearsFromBirth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Doğulduğu gündən ", _interpolate(_list(1)), " yaşa qədər"])}
  },
  "home": {
    "banners": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Friso AR"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflyuks üçün: keçibuynuzu ağacının lifi və hidrolizə olunmuş zülalla"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/1/packshot_en.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulyator"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜST-nın güvən nişanına istinadən indi körpələrin inkişaf göstəricilərini dərhal hesablaya bilirəm."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/2/device_en.png"])}
      }
    },
    "columns": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsullarımızın tam çeşidi ilə tanış olun və körpəniz üçün düzgün  seçim edin."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizin üçün hazırladığımız ən son elmi materialları yoxlayın ki,  daima yeniliklərdən və elmi nailiyyətlərdən xəbərdar olasınız."])},
      "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu maarifləndirici materialları paylaşmaqla valideynlərə hətta uzaqdan da kömək edin."])}
    },
    "app": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni tətbiqimiz nəhayət sizin üçün burada!"])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Tətbiqimizi artıq iPhone və ya Android telefonları üçün yükləməyin əlçatan olduğunu bildirməkdən çox məmnunuq!"])},
      "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/app-devices_en.png"])},
      "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/qr-code_az.png"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha heç bir şübhəniz olmasın..."])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ünvanınızı yazın və ən qısa zamanda sizinlə əlaqə saxlayacayıq."])},
      "inputA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ünvanınızı daxil edin"])},
      "inputB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualınızı bura yazın"])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlaqə tələb edin"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
      "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])}
    }
  },
  "login": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu vebsayt yalnız səhiyyə işçiləri üçün nəzərdə tutulub."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər girişiniz üçün artıq icazə verilibsə, aşağıdakı təfərrüatları daxil edin.<br/>Əgər siz səhiyyə mütəxəssisisinizə və bu vebsayt üçün etiqadnaməyə malik deyilsinizsə, <br/><u>Friso ilə əlaqə saxlayan şəxsdən soruşun</u>."])},
    "inpUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadəçi adı"])},
    "inpPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxil et"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials!"])}
  },
  "portfolio": {
    "banner": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü uşaqların sağlam böyüməsi və inkişafı üçün ən yaxşı qidadır."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü ilə qidalandırma seçimi olmadıqda məhsul çeşidlərimiz sadə və ya xüsusi qidalara ehtiyacı olan hər bir körpə üçün çıxış yolu təqdim edir."])}
    },
    "allProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün qatışıqlar"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusi qatışıqlar"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışıqlar"])},
    "notice": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü körpələrin sağlam böyüməsi və inkişafı üçün ən yaxşı qidadır. Analar adekvat qəbulu və keyfiyyətli ana südünü saxlamağa kömək etmək üçün düzgün ana qidalanması ilə bağlı təlimat almalıdırlar. Qismən və ya tam süni qidalanmanın və ya digər əlavə qidaların və ya içkilərin lazımsız tətbiqi ana südü ilə qidalanmaya mənfi təsir göstərə bilər ki, bu da geri dönməz ola bilər. Analar ana südü əvəzedicilərindən istifadə etməyə qərar verməzdən əvvəl və ya ana südü ilə qidalandırmaqda çətinlik çəkdikdə həkimləri ilə məsləhətləşməli və sosial və iqtisadi vəziyyəti nəzərə almalıdırlar. Ana südü əvəzedicilərinin istifadəsi, hazırlanması və saxlanması ilə bağlı təlimatlara diqqətlə əməl edilməlidir, çünki yersiz və ya lazımsız istifadə sağlamlıq üçün təhlükəli ola bilər."])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXUDUM VƏ ANLADIM"])}
    }
  },
  "product": {
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TƏRKİBİ"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAZIRLANMASI"])},
      "tableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təklif olunan qidalanma cədvəli"])},
      "instructions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAZIRLANMA TƏLİMATLARI:"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Diqqət:<br/>Qatışığı hazırladıqdan sonra ən geci bir saat ərzində istifadə edin."])}
      }
    },
    "nutricionalTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QİDALANMA CƏDVƏLİ"])},
    "frisoGold1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən - 6 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold 1-in tərkibində palma yağı yoxdur</li><li>Frisolac Gold 1 LockNutri texnologiyasına malikdir: minimal termiki emal zülalların qlikasiyasını məhdudlaşdırır, həzmə müsbət təsir göstərir</li><li>Mövcud ədəbiyyatlara əsasən prebiotik Qalaktooliqosaxaridlər (QOS) faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>DHA və ARA öyrənmə və idrak inkişafını dəstəkləyir</li><li>Tərkibində immuniteti dəstəkləyən nukleotidlər var</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deminerallaşmış zərdab, Yağsızlaşdırılmış süd, Bitki yağları (günəbaxan, Kanola (aşağı eruik turşu növü), kokos), Laktoza, Qalaktooliqosaxaridlər, Maltodekstrin, Balıq yağı, Xolin xlorid, Təkhüceyrəli yağ (Mortierella Alpina), Taurin, Günəbaxan Lesitini (emulqator), Minerallar: Kalsium Fosfat, Kalium Xlorid, Kalsium Karbonat, Natrium Xlorid, Maqnezium Sitrat, Dəmir Sulfat, Kalsium Hidroksid, Sink Sulfat, Mis Sulfat, Manqan Sulfat, Kalium Yodid, Natrium Selenit, Vitaminlər: Natrium-L-askorbat, Qarışıq tokoferollar, L-askorbil-palmitat, DL-alfa-tokoferol asetat, Nikotinamid, Kalsium D-pantotenat, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Riboflavin, Fitomenadion, D-Biotin, Xolekalsiferol, Nukleotidlər: Dinatrium inozin-5-monofosfat, Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-gold-1.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpənizin sağlamlığı üçün bu təlimatlara əməl edin. Bütün qabları gündə bir dəfə ən azı üç dəqiqə qaynadın."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qabları təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təmiz suyu 5 dəqiqə qaynadın və təqribən 40°C-dək soyudun."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl şüşəni və əmziyi isti su ilə yaxşıca yuyun."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya bir ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və qatışıq ilıq (37°C) olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksimum 1 saat ərzində istifadə edin. Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisoGold2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 aydan 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold 2-nin tərkibində palma yağı yoxdur</li><li>Frisolac Gold 2 LockNutri texnologiyasına malikdir: minimal termiki emal zülalların qlikasiyasını məhdudlaşdırır, həzmə müsbət təsir göstərir</li><li>Mövcud ədəbiyyatlara əsasən  prebiotik Qalaktooliqosaxaridlər (QOS) faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>DHA və ARA, öyrənmə və idrak inkişafını dəstəkləyir</li><li>Tərkibində immuniteti gücləndirən nukleotidlər var</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deminerallaşmış zərdab, Yağsızlaşdırılmış süd, Bitki yağları (günəbaxan, Kanola(aşağı eruik turşu növü),kokos), Laktoza, Qalaktooliqosaxaridlər, Maltodekstrin, Balıq yağı, Təkhüceyrəli yağ (Mortierella Alpina), Xolin Xlorid, Taurin, Günəbaxan Lesitini (emulqator), Minerallar: Kalsium Fosfat, Kalium Xlorid, Kalsium Karbonat, Natrium Xlorid, Maqnezium Sitrat, Dəmir Sulfat, Kalsium Hidroksid, Sink Sulfat, Mis Sulfat, Manqan Sulfat, Kalium Yodid, Natrium Selenit, Vitaminlər: Natrium-L-askorbat, Qarışıq tokoferollar, L-askorbil-palmitat, DL-alfa-tokoferol asetat, Nikotinamid, Kalsium D-pantotenat, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Riboflavin, Fol turşusu, Fitomenadion, D-Biotin, Xolekalsiferol, Nukleotidlər: Dinatrium inozin-5-monofosfat, Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-gold-2.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpənizin sağlamlığı üçün bu təlimatlara əməl edin. Bütün qabları gündə bir dəfə ən azı üç dəqiqə qaynadın."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qabları təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təmiz suyu 5 dəqiqə qaynadın və təqribən 40°C-dək soyudun."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl şüşəni və əmziyi isti su ilə yaxşıca yuyun."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya bir ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və qatışıq ilıq (37°C) olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksimum 1 saat ərzində istifadə edin. Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisoGold3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 3"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 3"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 yaşdan 3 yaşa qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold 3-ün tərkibində palma yağı yoxdur</li><li>Friso Gold 3 LockNutri texnologiyasına malikdir: minimal termiki emal zülalların qlikasiyasını məhdudlaşdırır, həzmə müsbət təsir göstərir</li><li>Mövcud ədəbiyyatlara əsasən  prebiotik Qalaktooliqosaxaridlər (QOS) faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>Tərkibində immuniteti gücləndirən nukleotidlər var</li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Qatı qlükoza siropu, Bitki yağları (günəbaxan, Kanola (aşağı eruik turşu növü), kokos), Saxaroza, Laktoza, Qalaktooligosaxaridlər, Balıq yağı, Xolin Xlorid, Təkhüceyrəli yağ (Mortierella Alpina), Mezo-inozitol, Taurin, Lesitin (emulqator), Minerallar: Kalsium Karbonat, Demir sulfat, Sink Sulfat, Manqan Sulfat, Mis Sulfat, Natrium Selenit, Kalium Yodid, Vitaminlər: Natrium-L-askorbat, Qarışıq tokoferollar, L-askorbil-palmitat, DL-alfa-tokoferol asetat, Nikotinamid, Kalsium D-pantotenat, Retinol asetat, Riboflavin, β-karotin, Tiamin hidroxlorid, Piridoksin hidroxlorid, Fol Turşusu, Fitomenadion, Xolekalsiferol, D-Biotin, Nukleotidler: Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, Dinatrium inozin-5-monofosfat, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Gündə 2-3 stəkan içmək tövsiyə edilir.</p><p>Friso Gold 3-ün səhv hazırlanması, saxlanması və istifadəsi uşağınızın sağlamlığına zərər vura bilər.</p><img class=\"table\" src=\"/img/products/preparation-table/friso-gold-3.svg\"/><p><small>1 ölçü qaşığı təxminən 5,5 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı üçün bu təlimatlara əməl edin. Bütün qabları gündə bir dəfə ən azı üç dəqiqə qaynadın. 40°C-yə (ilıq) qədər soyudulmuş 180 ml qaynadılmış suya 6 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin və qarışdırın."])}
    },
    "frisoVom1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Vom 1 Comfort"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Vom 1 Comfort"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaytarma, qəbizlik və sancının qidalanma ilə müalicə edilməsi"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən - 6 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Vom 1 Comfort LockNutri texnologiyasına malikdir: minimal termiki emal həzmə müsbət təsir göstərən zülalın qlikasiyasını məhdudlaşdırır</li><li>Frisolac Gold Vom 1 Comfort - Keçibuynuzu ağacının lifi (KBAL) ilə  qaytarma, qəbizlik və sancı hallarının tezliyini azaltmaqda effektivdir.</li><li>Keçibuynuzu ağacının liflərinin aşağıdakı hallarda təsirli olduğu göstərilmişdir:<ul><li>Sancılarda KBAL və Qalaktooliqosaxaridlər (QOS) faydalı bağırsaq mikroflorasının inkişafını stimullaşdırır</li><li>Qəbizlikdə KBAL nəcisin yumşalmasına kömək edir və onun tezliyini normallaşdırır</li><li>Qaytarmada KBAL qatışığı qatılaşdırır, bununla da qaytarmanın həcmini və tezliyini azaldır</li></ul></li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Deminerallaşmış zərdab, Bitki yağları (palma, palma çəyirdəyi, kanola (aşağı eruik turşu növü), günəbaxan), Laktoza, Keçibuynuzu ağacının kitrəsi, Qalaktooliqosaxaridlər, Maltodekstrin, Zərdab zülalı konsentratı, Balıq yağı, Xolin xlorid, Təkhüceyrəli yağ (Mortierella Alpina), Mezo-inozitol, Taurin, Günəbaxan Lesitini (emulqator), Minerallar: Kalium Sitrat, Trikalsium Fosfat, Kalsium Karbonat, Natrium Xlorid, Maqnezium Sitrat, Kalium xlorid, Kalsium hidroksid, Dəmir Sulfat, Sink Sulfat, Mis Sulfat, Manqan Sulfat, Kalium Yodid, Natrium Selenit, Vitaminlər: Natrium-L-askorbat, L-askorbil- palmitat, Qarışıq tokoferol konsentratı, Kalsium D-pantotenat, DL-alfa-tokoferol asetat, Nikotinamid, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitamenadion, D-Biotin, Xolekalsiferol. Nukleotidlər: Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, Dinatrium inozin-5-monofosfat, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-vom-comfort-1.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpənizin sağlamlığı üçün bu təlimatlara əməl edin. Bütün qabları gündə bir dəfə ən azı üç dəqiqə qaynadın. Keçibuynuzu ağacının kitrəsi qatışığı daha qatı etdiyi üçün iri dəlikli əmzikdən istifadə etmək tövsiyə olunur."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təmiz suyu 5 dəqiqə qaynadın və təqribən 75°C-dək soyudun."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl şüşəni və əmziyi isti su ilə yaxşıca yuyun."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya bir ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold VOM 1 Comfort-u tez soyudun. Temperaturu yoxlayın və qatışıq ilıq (37°C) olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksimum 1 saat ərzində istifadə edin. Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisoVom2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Vom 2 Comfort"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Vom 2 Comfort"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaytarma, qəbizlik və sancının qidalanma ilə müalicə edilməsi"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 aydan 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Vom 2 Comfort LockNutri texnologiyasına malikdir: minimal termiki emal həzmə müsbət təsir göstərən zülalın qlikasiyasını məhdudlaşdırır</li><li>Frisolac Gold Vom 2 Comfort - Keçibuynuzu ağacının lifi (KBAL) ilə  qaytarma, qəbizlik və sancı tezliyini effektiv şəkildə azaldır</li><li>Keçibuynuzu ağacının liflərinin aşağıdakı hallarda təsirli olduğu göstərilmişdir:<ul><li>Sancılarda KBAL və Qalaktooliqosaxaridlər (QOS) faydalı bağırsaq mikroflorasının inkişafını stimullaşdırır</li><li>Qəbizlikdə KBAL nəcisin yumşalmasına kömək edir və onun tezliyini normallaşdırır</li><li>Qaytarmada KBAL qatışığı qatılaşdırır, bununla da qaytarmanın həcmini və tezliyini azaldır</li></ul></li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Deminerallaşmış zərdab, Bitki yağları (palma, palma çəyirdəyi kanola (aşağı eruik turşu növü), günəbaxan), Laktoza, Keçibuynuzu ağacının kitrəsi, Qalaktooliqosaxaridlər, Maltodekstrin, Balıq yağı, Xolin xlorid, Təkhüceyrəli yağ (Mortierella Alpina), Taurin, Günəbaxan Lesitini (emulqator), Minerallar: Dikalsium fosfat, Kalium sitrat, Trikalsium fosfat, Kalsium karbonat, Natrium Xlorid, Maqnezium Sitrat, Kalium xlorid, Maqnezium hidrogen fosfat, Dəmir Sulfat, Kalsium Hidroksid, Sink Sulfat, Mis Sulfat, Manqan Sulfat, Kalium Yodid, Natrium Selenit), Vitaminlər: Natrium-L-askorbat, L-askorbil-palmitat, Qarışıq tokoferol konsentratı, Kalsium D-pantotenat, DL-alfa-tokoferol asetat, Nikotinamid, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitomenadion, D-Biotin, Xolekalsiferol), Nukleotidlər: Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, Dinatrium inozin-5-monofosfat, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-vom-comfort-2.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpənizin sağlamlığı üçün bu təlimatlara əməl edin. Bütün qabları gündə bir dəfə ən azı üç dəqiqə qaynadın. Keçibuynuzu ağacının kitrəsi qatışığı daha qatı etdiyi üçün iri dəlikli əmzikdən istifadə etmək tövsiyə olunur."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təmiz suyu 5 dəqiqə qaynadın və təqribən 75°C-dək soyudun."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl şüşəni və əmziyi isti su ilə yaxşıca yuyun."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya bir ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold VOM 2 Comfort-u tez soyudun. Temperaturu yoxlayın və qatışıq ilıq (37°C) olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksimum 1 saat ərzində istifadə edin. Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisolacPremature": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Prematuros"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Prematuros"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaxtından əvvəl doğulmuş və az çəkisi olan körpələr üçün."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən vaxtında doğulmuş uşağın çəkisinə çatana qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>ESPGHAN tərəfindən vaxtından əvvəl doğulmuş körpələr üçün tövsiyə edildiyi kimi yüksək səviyyədə zülal, enerji, mikroelementlər və doymamış yağ turşuları ilə təmin olunmaq üçündür.</li><li>Frisolac Gold Prematuros– un tərkibində qismən hidrolizə edilmiş zərdab zülalları və yüksək dərəcədə hidrolizə olunmuş kazein zülalları var.</li><li>Doymamış yağ turşuları,  DHA, EPA, ARA körpənin beyin və görmə inkişafna kömək edir</li><li>OZT (orta zəncirli triqleserid) yağları enerji tələbatını ödəyir.</li><li>Qalaktooliqosaxaridlər (QOS) prebiotiklərin yaxşı bağırsaq mikroflorası üçün faydalı olduğu tədqiqatlardan məlumdur.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru süd, Laktoza, Bitki yağları, Hidrolizə edilmiş zərdab zülalı, Maltodekstrin, Hidrolizə edilmiş kazein, Emulqator (mono və diqliseridlərin limon turşusu efirləri,) QOS, Di-kalsium fosfat, Tri- kalsium fosfat, Balıq yağı, Təkhüceyrəli yağ (Mortierella alpina), Kalsium karbonat, Maqnezium xlorid, Natrium xlorid, Natrium L- askorbat, Mio-inozitol, Xolin xlorid, Taurin, Dəmir sulfat, DL-α-tokoferol asetat, Sink sulfat, Nikotinamid, Emulqator (Günəbaxan lesitini), L-karnitin, Sitidin-5'- monofosfor turşusu, L-askorbil palmitat, Kalsium D pantotenat, Dinatrium uridin-5'- monofosfat, Adenozin-5'-monofosfor turşusu, Dinatrium quanozin-5'-monofosfat, Retinol asetat, Dinatrium inozin-5'- monofosfat, Mis sulfat, Riboflavin, Tiamin hidroxlorid, Piridoksin hidroxlorid, Fol turşusu, Kalium yodid, Fitaminadion, Xolekalsiferol, Natrium selenit, D-biotin, Manqan sulfat, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Frisolac<sup>®</sup> Gold Prematuros-un tərkibi gündə hər kq çəki üçün 150 ml hazırlanmış süd qatışığının istehlakına əsaslanır.</p><img class=\"table\" src=\"/img/products/preparation-table/frisolac-premature.svg\"/><p><small>1 ölçü qaşığı təxminən 5,2 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı hazırlıq və istifadə təlimatlarına düzgün riayət etməkdən asılıdır."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl lazımi qabları yuyun və qaynadın."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya 1 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və Frisolac Gold Prematuros ilıq olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisolacGoldSinLactosa": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Sin Lactosa"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Sin Lactosa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpələrdə laktoza dözümsüzlüyü və kəskin diareyanın müalicəsi üçün qida"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Sink diareyanın şiddətini və müddətini azaldır və patogenlərin daha yaxşı çıxmasına imkan verir.</li><li>Dəmirin optimal miqdarı uzunmüddətli infeksion diareya riski olmadan körpənin düzgün böyüməsini və inkişafını təmin edir.</li><li>Orta zəncirli triqliseridlər (OZT) bağırsağa əlavə stress olmadan asanlıqla mənimsənilən enerji mənbəyini təmin edir.</li><li>Nişasta: rehidratasiya terapiysının uğursuzluğunu azaltmaq üçündür.</li><li>İnulin: Həzm sistemini və nəcisin formalaşmasını dəstəkləyir.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qlükoza siropu, Bitki yağları, Orta zəncirli trigliserid yağı, Zərdab zülalı konsentratı, Nişasta, Natrium kazeinat, Frukto-oliqosaxaridlər (inulin), Kalium xlorid, Tri-kalsium fosfat, Xolin bitartrat, Maqnezium fosfat, Kalsium karbonat, Natrium sitrat, Balıq yağı, Kalium sitrat, Təkhüceyrəli yağ (Mortierella alpina), Natrium L-askorbat, Yağsızlaşdırılmış süd, Taurin, Mio- inozitol, Emulqator (Günəbaxan lesitini), Sink sulfat, Dəmir sulfat, L-karnitin tartrat, L-askorbil palmitat, Dinatrium uridin-5'- monofosfat, Kalsium D- pantotenat, DL-α-tokoferol asetat, Nikotinamid, Sitidin-5'-monofosfor turşusu, Antioksidant (tokoferolla zəngin ekstrakt), Dinatrium inozin-5'- monofosfat, Adenozin-5'- monofosfor turşusu, Dinatrium quanozin-5'– monofosfat, Mis sulfat, Riboflavin, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Manqan sulfat, Kalium yodid, Fol turşusu, Fitomenadion, Natrium selenit, D- Biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-gold-sin-lactosa.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı hazırlıq və istifadə təlimatlarına düzgün riayət etməkdən asılıdır."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl lazımi qabları yuyun və qaynadın."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya 1 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və Frisolac Gold Sin Lactosa ilıq olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisoPep": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold PEP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold PEP"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü zülallarına olan allergiyanın yüngül və orta dərəcəli simptomlarının müalicəsi üçün"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP dərin şəkildə hidrolizə edilmiş zərdab zülallarına malikdir: peptidlərin təxminən 37%-i &lt;3 amin turşusundan ibarətdir.</li><li>QOS sağlam bağırsaq florası üçün</li><li>DHA/ARA görmə və koqnitiv inkişaf üçün</li><li>Zülal çirklənməsini minimuma endirmək üçün laktoza səviyyəsinin azaldılması</li><li>İmmuniteti dəstəkləmək üçün nukleotidlərlə</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza (süddən), Qatı qlükoza siropu, Bitki yağları, Palma yağı, Palma çəyirdəyi yağı, Kanola yağı (aşağı erucic turşusu növü), Günəbaxan yağı, Zərdab zülalı, hidrolizatı (süddən), Maltodekstrin, Qalaktooliqosaxaridlər, Emulqator (mono və diqleseridlərin limon turşusu efirləri), Dikalsium fosfat, Trikalsium fosfat, Maqnezium xlorid, Kalium xlorid, Xolin xlorid, Natrium sitrat, Natrium xlorid, Natrium L-askorbat, Mortierella alpina yağı, Schizochytrium sp. mikroyosundan DHA ilə zəngin yağ, L-tirozin, Mio-inozitol, Trikalium sitrat, L-fenilalanin, Taurin, Dəmir sulfat, Emulqator (günəbaxan lesitini), L-karnitin tartrat, Sink sulfat,  Dinatrium uridin-5-monofosfat, Kalsium D-pantotenat, Sitidin-5-monofosfor turşusu, DL α-tokoferol asetat, Nikotinamid, Dinatrium inozin-5-monofosfat, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat, Mis sulfat, Tiamin hidroxlorid, Riboflavin, Kalsium karbonat, Piridoksin hidroxlorid, Retinol-asetat, Manqan sulfat, Fol turşusu, Kalium yodid, Fitomenadion, Natrium selenit, D-Biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/friso-pep.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpənizin sağlamlığı üçün bu təlimatlara əməl edin."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təmiz suyu 5 dəqiqə qaynadın və təqribən 40°C-dək soyudun."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl şüşəni və əmziyi isti su ilə yaxşıca yuyun."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutudakı qaşıq ilə hər 30 ml suya 1 ölçü qaşığı toz (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və qatışıq ilıq (37°C) olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisoPepAc": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold PEP AC"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold PEP AC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü zülallarına olan allergiyanın kəskin simptomlarının müalicəsi üçün"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP AC  tərkibində yüksək dərəcədə hidrolizə edilmiş kazein zülalları var: peptidlərin 60%-i ‹ 3 amin turşusundan ibarətdir.</li><li>Friso PEP AC laboratoriya şəraitində T-hüceyrələrinin proliferasiyasına və iltihab əleyhinə sitokinlərin sərbəst buraxılmasına səbəb olmur.</li><li>Laktozasız, hər hansı zülal çirklənməsinin qarşısını almaq üçün.</li><li>İmmuniteti dəstəkləyən nukleotidlərlə.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatı qlükoza siropu, Bitki yağları, Tam hidrolizə olunmuş kazein zülalı, Emulqator (mono və diqliseridlərin limon turşusu efirləri), Trikalsium fosfat, Kalium xlorid, Maqnezium xlorid, Kalsium karbonat, Kalsium hidrogen fosfat, Xolin xlorid, L- sistin, L-triptofan, Mortierella alpina, mikro yosundan zəngin yağ ARA, Schizochytrium sp. mikro yosundan zəngin yağ DHA, Mio-inozitol, Natrium L- askorbat, Taurin, Dəmir sulfat, Emulqator (Günəbaxan lesitini), L- karnitin tartrat, Sink sulfat, Kalsium D- pantotenat, Dinatrium uridin- 5'- monofosfat, DL-α-tokoferol asetat, Sitidin-5'-monofosfor turşusu, Adenozin- 5'-monofosfor turşusu, Dinatrium inozin- 5'-monofosfat, Nikotinamid, Dinatrium quanozin-5 '-monofosfat, Tiamin hidroxlorid, Riboflavin, Mis sulfat, Piridoksin hidroxlorid, Retinol asetat, Manqan sulfat, Kalium yodid, Fol turşusu, Fitomenadion, Natrium selenit, D-biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/friso-pep-ac.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı hazırlıq və istifadə təlimatlarına düzgün riayət etməkdən asılıdır."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl lazımi qabları yuyun və qaynadın."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya 1 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və Frisolac Gold Pep AC ilıq olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisolacGoldHa": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold HA"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup>Gold HA"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ailə anamnezinə əsaslanaraq risk qrupunda olan körpələrdə inək südü zülalına qarşı allergiyanın (İSZA) inkişaf riskini azaltmağa kömək etmək üçün"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold HA inək südünə qarşı həssaslığın qarşısını alan qismən hidrolizə edilmiş zərdab zülallarına malikdir.</li><li>QOS-un sağlam bağırsaq florası üçün faydalı olduğu tədqiqatlardan məlumdur.</li><li>DHA/ARA görmə və beyin inkişafı dəstəkləyir.</li><li>Nukleotidlər immun sisteminin inkişafını stimullaşdırır.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru süd, Laktoza, Bitki yağları, Hidrolizə edilmiş zərdab zülalı, Maltodekstrin, QOS, Emulqator (mono və diqliseridlərin limon turşusu efirləri), Di- kalsium fosfat, Tri-kalsium fosfat, Maqnezium xlorid, Xolin xlorid, Balıq yağı, Təkhüceyrəli yağ (Mortierella alpina), Mio-inozitol, Natrium L- askorbat, Kalium xlorid, L-fenilalanin, L-tirozin, Taurin, Natrium xlorid, Dəmir sulfat, L-karnitin tartrat, Emulqator (günəbaxan lesitini), Sink sulfat, L-askorbil palmitat, Dinatrium uridin- 5'-monofosfat, Kalsium D-pantotenat, Antioksidant (tokoferolla zəngin ekstrakt), Sitidin-5'-monofosfor turşusu, DL-α-tokoferol asetat, Dinatrium inozin-5'-monofosfat, Adenozin-5'-monofosfor turşusu, Nikotinamid, Dinatrium quanozin-5 '-monofosfat, Mis sulfat, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Manqan sulfat, Kalium yodid, Fol turşusu, Fitomenadion, Natrium selenit, D-biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-gold-hipoalergenico.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı hazırlıq və istifadə təlimatlarına düzgün riayət etməkdən asılıdır."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl lazımi qabları yuyun və qaynadın."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya 1 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və Frisolac Gold Hipoalergenico ilıq olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    },
    "frisolacGoldComfortAr": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Comfort AR"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Comfort AR"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qastroezofaqal reflyuksun qidalanma ilə müalicə edilməsi."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Comfort AR-in tərkibində reflyuks sürətini azaltmaqda təsirli olduğu sübut edilmiş optimal miqdarda Keçibuynuzu ağacının lifləri var.</li><li>QOS prebiotiklər sağlam bağırsaq mikrobiotasını dəstəkləyir</li><li>Qismən hidrolizə edilmiş zərdab zülalı mədə boşalmasını sürətləndirir.</li><li>DHA və ARA öyrənmə və idrak inkişafını dəstəkləyir.</li><li>Tam laktoza: körpələr üçün optimal karbohidratdır.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru süd, Yağsızlaşdırılmış süd, Deminerallaşdırılmış süd zərdabı, Laktoza, Zərdab zülal konsentratı, Bitki yağları, Hidrolizə edilmiş zərdab zülalı, Keçibuynuzu ağacının lifləri, Maltodekstrin, QOS, Di- kalsium fosfat, Tri-kalsium fosfat, Emulqator (mono və diqliseridlərin limon turşusu mürəkkəb efiri), Balıq yağı, Kalium sitrat, Kalsium karbonat, Xolin xlorid, Natrium xlorid, Təkhüceyrəli yağ (Mortierella alpina), Ditrik turşusunun maqnezium duzları, Kalium xlorid, Natrium L-askorbat, Maqnezium xlorid, Mio-inozitol, Taurin, Dəmir sulfat, Emulqator (günəbaxan lesitini), Kalsium hidroksid, L-fenilalanin, L-tirozin, Sink sulfat, Dinatrium uridin- 5'-monofosfat, L-askorbil palmitat, Kalsium D- pantotenat, Antioksidant (tokoferolla zəngin ekstrakt), Sitidin-5'-monofosfor turşusu, DL-α-tokoferol asetat, Dinatrium inozin-5'-monofosfat, Nikotinamid, Adenozin-5'-monofosfor turşusu, L-karnitin tartrat, Dinatrium quanozin-5 '-monofosfat, Mis sulfat, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Manqan sulfat, Fol turşusu, Kalium yodid, Fitomenadion, Natrium selenit, D-biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table/frisolac-gold-comfort-ar.svg\"/><p><small>1 ölçü qaşığı təxminən 4,3 qramdır</small></p>"])},
      "preparationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı hazırlıq və istifadə təlimatlarına düzgün riayət etməkdən asılıdır."])},
      "preparationNumber1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
      "preparationNumber2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
      "preparationNumber3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadədən dərhal əvvəl lazımi qabları yuyun və qaynadın."])},
      "preparationNumber4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu şüşəyə əlavə edin."])},
      "preparationNumber5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qutunun içərisindəki qaşıqdan istifadə edərək hər 30 ml suya 1 ölçü qaşığı qatışıq tozu (təpəcik olmadan) əlavə edin."])},
      "preparationNumber6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tam həll olunana qədər yaxşıca qarışdırın və ya çalxalayın."])},
      "preparationNumber7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və Frisolac Gold Comfort AR ilıq olduqda körpənizi qidalandırın."])},
      "preparationNumber8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı orijinal yerinə qoyun və qapağı bağlayın."])},
      "preparationNumber9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatışığı hazırladıqdan sonra maksiumum 1 saat ərzində istifadə edin."])},
      "preparationNumber10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır qatışığın qalığından sonrakı qidalanma üçün istifadə etməyin."])}
    }
  }
}